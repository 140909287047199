import React from 'react'
import { Button, Text } from 'theme-ui'
import JSONData from '@posts/articles/all-kits-with-pics/all-kits.json'
import _ from 'lodash'
import PropTypes from 'prop-types'

const searchDatabaseForProduct = ({ id }) => {
  const item = JSONData.content.filter((el, array, index) => {
    return (
      id === el.id ||
      id === el.slug ||
      id === el.url ||
      id === el.name ||
      id === el.sku ||
      id === el.image
    )
  })

  // we return the first element from the array, which has the item
  return _.head(item)
}

function normaliseObjectForHtmlAttributes(item) {
  // below, we are replacing underscores with hyphens in the keys from the "all-kits" product, and
  // prefixing the key with "data-item-", turning "image" into "data-item-image"
  return _.mapKeys(item, (value, key) => {
    return `data-item-${_.replace(key, '_', '-')}`
  })
}

const BuyButton = ({ productId, includePrice, children }) => {
  const item = searchDatabaseForProduct({ id: productId })
  const itemNormalised = normaliseObjectForHtmlAttributes(item)
  // we need to update the item's image with the public facing URL of the image
  // itemNormalised['data-item-image-url'] = imageSearch()

  return (
    <>
      <Button mr={2} className='snipcart-add-item' {...itemNormalised}>
        <Text>
          {children ?? `Add To Cart`}{' '}
          {includePrice ? ` - AUD$${item.price}` ?? '' : ''}
        </Text>
      </Button>
    </>
  )
}

BuyButton.propTypes = {
  productId: PropTypes.string.isRequired,
  includePrice: PropTypes.bool
}

BuyButton.defaultProps = {
  includePrice: false
}

export default BuyButton
