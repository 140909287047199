import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@theme-ui/mdx'
import components from '@components/Mdx'
import BuyButton from '@teComponents/BuyButton'
import { Message } from 'theme-ui'

const shortcodes = { BuyButton, Message }

export const PostBody = ({ body }) => {
  return (
    <MDXProvider components={{ ...components, ...shortcodes }}>
      <MDXRenderer>{body}</MDXRenderer>
    </MDXProvider>
  )
}
