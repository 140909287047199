import React, { useEffect } from 'react'
import { Box, Text } from 'theme-ui'

const styles = {
  container: {
    border: `solid red`,
    height: `200px`,
    display: 'block'
  }
}

const GoogleAdsense = () => {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      if (window) {
        try {
          ;(window.adsbygoogle = window.adsbygoogle || []).push({})
        } catch (error) {
          console.log(error, 'adsense error')
        }
      }
    }
  })

  return (
    <Box>
      <ins
        className='adsbygoogle'
        style={styles.container}
        data-ad-client={process.env.GOOGLE_ADSENSE_CLIENT_ID}
        data-adtest='on'
        //data-ad-slot='XXXXXXX'
        data-ad-format='auto'
        data-full-width-responsive='true'
      ></ins>
    </Box>
  )
}

export default GoogleAdsense
